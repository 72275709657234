import React from 'react';
import UncontrolledCarousel from 'reactstrap/lib/UncontrolledCarousel';
import Layout from '../components/Layout';
import ContactForm from '../components/ContactForm';
import homeJson from '../data/home.json';
import { Link } from 'gatsby';

const items = homeJson.carousel.map((item, i) => ({
  src: item.imgSrc,
  altText: `Yard Art Landscaping LLC's Example Work - Image ${i + 1}`,
  key: `carousel-${i}`,
}));

const HomePage = () => {
  return (
    <Layout pageWrapper="home">
      <div>
        <UncontrolledCarousel items={items} />

        <div className="container">
          <img className="img-fluid" src={homeJson.headerImage} alt="Yard Art Landscaping LLC's Services" />
        </div>

        <div className="d-md-none text-center my-5">
          <Link to="/portfolio">
            <button className="btn btn-primary bold">View Portfolio</button>
          </Link>
        </div>

        <section className="main-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <h2 className="mb-3 text-center">Get a Quote </h2>
                <ContactForm />
              </div>
            </div>
          </div>
        </section>
      </div>

    </Layout>
  );
};

export default HomePage;
